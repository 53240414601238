import {request} from "../../../util/Request";

const FORM_STATE = {
    id: null,
    comments: null,
    status: null,
    _method: 'post',
}

export default {
    data() {
        return {
            formFields: {...FORM_STATE},
            selectedLoad: null
        }
    },
    methods: {
        handleInTransitClick(record) {
            this.formFields.status = 6
            this.formFields.id = record.id
            this.selectedLoad = record
        },
        handleUnLoadClick(record) {
            this.formFields.status = 7
            this.formFields.id = record
            this.selectedLoad = record
        },
        handleAcceptClick(record) {
            this.formFields.status = 4
            this.formFields.id = record.id
            this.selectedLoad = record
        },
        handleRejectClick(record) {
            this.formFields.status = 3
            this.formFields.id = record.id
            this.selectedLoad = record
        },
    },
    computed: {
        title() {
            if (this.formFields.status == 6) {
                return this.$t('title.loadInTransit')
            } else if (this.formFields.status == 7) {
                return this.$t('title.loadUnloaded')
            } else if (this.formFields.status == 4) {
                return this.$t('title.acceptLoad')
            } else if (this.formFields.status == 3) {
                return this.$t('title.rejectLoad')
            }
        }
    }
}
